<template>
  <div class="Application">
    <div class="container">
      <b-alert class="row mt-2 alert-success" variant="success" :class="alert_class" style="width:89%;" :show="showDismissibleAlert" dismissible>{{success_msg}}</b-alert>
      <div class="row" style="margin-top: 15px;margin-bottom: 15px;overflow: hidden;">
        <div class="col-md-5" style="padding: 0;">
          <div class="col-md-12">
            <img :src="$S3_bucket_url+job.company_logo_url" v-if="job&&job.company_logo_url" alt="" style="width: 200px;align-items: center;max-width: 100%;">
            <h1 class="mb-3" style="color: #24448f;font-weight: bold;font-size: 35px;">Registration Form</h1>
          </div>
          <div class="col-md-12" style="padding: 0;position: relative;top: 7%; transform: translateY(-10%);"  v-if="job&&job.custom&&job.custom.form_img">
              <img :src="$S3_bucket_url+job.custom.form_img" :alt="job.job_designation" :title="job.job_designation" class="banner" style="max-height:100%; max-width:100%;">
          </div>
        </div>
        <div class="col-md-7" style="padding: 40px;">
          <div class="row">
            <v-form ref="applicationForm" lazy-validation v-model="valid">
              <div class="row">
                <div class="col-md-6 gap">
                  <div label-for="name-input">
                    <v-text-field type="text" id="name-input" v-model="register.name" :rules="[v => !!v || 'Enter name']" outlined><template #label>Full name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                </div>
                <div class="col-md-6 gap">
                  <div label-for="register-mobile">
                    <v-text-field type="number" id="mobile-input" v-model="register.mobile" :rules="mobileRules" outlined @keydown.space.prevent  hide-spin-buttons><template #label>Mobile<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 gap">
                  <div label-for="register-email" v-click-outside="hideEmailExtensions">
                    <v-text-field type="text" id="email-input" v-model="register.email" :rules="emailRules"  @keyup="getExtension" @keydown.space.prevent outlined><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                    <div class="email-dropdown-list-container" v-show="showExtensions">
                      <ul class="email-dropdown-list">
                        <li tabindex="-1" data-dropdown-item-index= index class="email-dropdown-item" v-for="(domain,index) in selectedDomains" :key="index" @click="setExtension(domain)">
                          <span class="email-dropdown-item-domain">@{{domain}}</span>
                        </li>
                      </ul>
                    </div>                  
                  </div>
                </div>
                <div class="col-md-6 gap">
                  <div label-for="register-state">  
                    <v-autocomplete id="state-input" v-model="register.city" :items="locations" item-text="text" item-value="id" :rules="[v => !!v || 'Select current city']" clearable outlined> 
                      <template #label>Current city<span class="red--text"><strong>* </strong></span></template>
                      <template slot="item" slot-scope="data">
                        <template>
                          <v-list-item-content>{{data.item.text}}</v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 gap">
                  <div label-for="register-jobrole">
                    <v-autocomplete id="role-input" v-model="register.jobrole" :items="jobrole" item-value="value" item-text="text" :rules="[v => v.length!=0 || 'Select jobrole']" clearable chips outlined multiple deletable-chips><template #label>Jobrole<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                  </div>
                </div>
                <div class="col-md-6 gap">
                  <div label-for="register-experience">
                    <v-autocomplete  id="exp-input" :items="exp.options" item-value="value" item-text="text" :searchable="true" v-model="register.experience" :rules="[v => !!v || 'Select experience']" clearable outlined><template #label>Experience<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                  </div> 
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 gap">
                  <v-autocomplete id="qual-input" v-model="register.highest_qualification" :searchable="true" :items="courses" item-text="text" item-value="id" :rules="[v => !!v || 'Select highest qualification']" @change="getSpecification" outlined>
                    <template #label>Highest qualification<span class="red--text"><strong>* </strong></span></template>
                    <template slot="item" slot-scope="data">
                      <template>
                          <v-list-item-content>{{data.item.text}}</v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
                <div class="col-md-6 gap">
                  <div label-for="register-specialisation">
                    <v-autocomplete id="spec-input" v-model="register.specialisation" :items="spec" item-value="value" item-text="text" :rules="[v => !!v || 'Select specialisation']" outlined><template #label>Specialisation<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 gap">
                  <div label-for="register-percentage">
                    <v-text-field id="perc-input" v-model="register.highest_qualification_percentage" type="number" :rules="[v => !!v || 'Enter qualification %']"  outlined hide-spin-buttons><template #label>Highest qualification %<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                </div>
                <div class="col-md-6 gap">
                  <div label-for="register-passout">
                    <v-autocomplete id="pass-input" v-model="register.passout_year" :items="years" item-value="value" item-text="text" :rules="[v => !!v || 'Select year of passout']" outlined><template #label>Year of passout<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                  </div>
                </div>
              </div>      
              <div class="row">
                <div class="col-md-6 gap" v-for="(form,key) in application_form" :key="form.qid">
                  <div label-for="">
                    <div v-if="form.question_type == 1">
                      <v-text-field :type="form.type" v-model="answers[key].answer" class="w-100" placeholder="..." outlined >
                        <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong> * </strong></span></template>
                      </v-text-field>
                    </div>
                    <div v-else-if="form.question_type == 2">
                      <v-textarea type="text" v-model="answers[key].answer" cols="30" placeholder="..." rows="5" outlined>
                        <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong> * </strong></span></template>
                      </v-textarea>
                    </div>
                    <div v-else-if="form.question_type == 3">
                      <label for="">{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker"><strong> * </strong></span></label>
                        <div v-if="form.answers.length < 5">
                          <b-form-checkbox v-model="answers[key].ans_id" :name="ans.answer" :value="ans.ans_id" v-for="(ans,index) in form.answers" :key="index">{{ans.answer}}</b-form-checkbox>
                        </div>
                        <div v-else>
                          <b-form-select v-model="answers[key].ans_id" class="mb-3">
                            <b-form-select-option :value="null" disabled>Choose branch</b-form-select-option>
                            <b-form-select-option :name="ans.answer" :value="ans.ans_id" v-for="(ans,index) in form.answers" :key="index">{{ans.answer}}</b-form-select-option>
                          </b-form-select>
                        </div>
                    </div>
                    <div v-else-if="form.question_type == 7">
                      <label for="">{{form.question}} &nbsp;<span class="red--text" v-if="answers[key].deal_breaker"><strong> * </strong></span></label>
                      <button class="btn btn-primary mb-5" @keydown.enter.prevent @click="openVideoModal($event, 'video', key)">Record answer</button>
                    </div>
                    <!-- <div v-else-if="form.answers&&form.answers.length && (form.question_type== 3 || form.question_type== 4)">
                      <label for="">{{form.question}}</label>
                      <v-radio-group v-model="answers[key].ans_id" inline id="radio-group">
                        <v-radio :label="ans.answer" color="primary" :value='ans.ans_id' v-for="(ans,index) in form.answers" :key="index"></v-radio>
                      </v-radio-group>
                    </div> -->
                    <b-form-group v-else-if="form.answers&&form.answers.length && (form.question_type== 3 || form.question_type== 4)">
                      <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong> * </strong></span></template>
                      <b-form-radio v-model="answers[key].ans_id" :aria-describedby="ariaDescribedby" :value="ans.ans_id" v-for="(ans,index) in form.answers" :key="index">{{ans.answer}}</b-form-radio>
                    </b-form-group>
                    <div v-else-if="form.question_type ==5 && form.autocomplete == true">
                      <v-autocomplete v-if="form.qp_id == 4 || form.qp_id == 23" v-model="answers[key].ans_id" :label="form.question" :items="locations" item-text="text" item-value="id" clearable outlined> 
                        <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong> * </strong></span></template>
                        <template slot="item" slot-scope="data">
                          <template>
                            <v-list-item-content>{{data.item.text}}</v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete v-else v-model="answers[key].ans_id" :items="form.values" item-text="text" item-value="id" clearable outlined> 
                        <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong>* </strong></span></template>
                        <template slot="item" slot-scope="data">
                          <template>
                            <v-list-item-content>{{data.item.text}}</v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div v-else-if="form.question_type == 5 && form.autocomplete ==false">
                      <v-autocomplete v-model="answers[key].answer" :items="form.values" item-text="text" item-value="id" :multiple="form.multiple" outlined :chips="form.multiple" :deletable-chips="form.multiple">
                        <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong>* </strong></span></template>
                      </v-autocomplete>
                    </div>
                    <div v-else-if="form.question_type == 6">
                      <v-file-input  v-model="upload_data" :append-icon="$icons.file" prepend-icon="" accept="application/pdf" @change="uploadFile(upload_data,key)" show-size outlined>
                        <template #label>{{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong>* </strong></span></template>
                      </v-file-input>
                      <!-- <v-progress-linear :value="upload_progress" v-if="upload_data && upload_progress != 100" color="light-blue" height="10" striped></v-progress-linear> -->
                    </div>
                  </div>
                </div>
              </div> 
                <span style="color:red;font-size:0.75rem" v-if="validation_error">{{validation_error}}</span>
                           
              <div class="row mt-4">
                <div class="col text-center">
                  <b-button v-if="!loading" style="color:#fff !important" variant="primary" class="btn mb-2" @click="submitForm()" :disabled="uploading">Submit</b-button>
                  <b-button v-if="loading" style="color:#fff !important" variant="primary" class="btn mb-2">...</b-button>
                  <span style="color:red;font-size:0.75rem">{{upload_error}}</span>
                </div>
              </div>
            </v-form>
          </div>
          <VideoModal :id="current_key" :open="videoModal.open" @reset_modal="resetModal" @video_path="getVideoPath"/>

          <div class="row">
            <div class="col">
              <p style="font-size: 12px;color: #898989;">Safety alert: We are not charging&nbsp;any kind of payment for a job or hiring process. Kindly stay away from such false promises</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <!--footer-->
        <div class="col" style="background-color: #b6b5b5;">
          <div class="container">
            <div class="row ">
              <div class="col-lg-5 alignmob">
                <!-- <p class="content-center" style="color: #fff;padding: 10px">© 2022 <a class="flink" style="color: white" href="">Hireclap</a> | Hire best matching candidates</p> -->
                <p class="content-center">© 2023 <a class="flink" target="_blank" href="https://www.hireclap.com/"><img height="50px" src="https://s3.ap-south-1.amazonaws.com/www.hireclap.com/Assets/images/hireclap-logo-HD.png" alt="Hireclap" title="Hireclap | Hire best matching candidates"></a></p>
              </div>
              <div class="col-lg-4 alignmob"><p class="content-center" style="padding-top: 15px;">
                <a href="" target="_blank">Made with <span style="color: #e74c3c">♥</span> in India</a>. </p>
              </div>
              <div class="col-lg-3 alignmob">
                <a href="https://www.facebook.com/HireClap"><v-icon class="mdi-footer mdi" >{{ $icons.facebook }}</v-icon></a>
                <a href="https://twitter.com/Hireclap"><v-icon class="mdi-footer">{{ $icons.twitter }}</v-icon></a>
                <a href="https://www.linkedin.com/company/hireclap"><v-icon class="mdi-footer">{{ $icons.linkedin }}</v-icon></a>
                <a href="https://www.youtube.com/channel/UC_Rhjfe3TniDTGLn8nxcu0Q"><v-icon class="mdi-footer">{{ $icons.youtube }}</v-icon></a>
                <a href="https://www.instagram.com/hireclap"><v-icon class="mdi-footer">{{ $icons.instagram }}</v-icon></a>
              </div>
            </div>
          </div>    
        </div>
        <!--footer end-->
    </div>
  </div>
</template>
<script>
const VideoModal = () => import(/* webpackPrefetch: true */ "@/components/VideoModal");
import Actions from '../libraries/apiActions.js'
import AwsUpload from "../../utils/s3upload"
import validation from '../libraries/validFormat'; 
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
// import {eventBus} from '../main';
import '../assets/hire.css'
export default {
  name:'PrivateJobApplicationForm',
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  components: {
    VideoModal
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    }),
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1950}, (value, index) => 1951 + index)
    }
  },
  data(){
    return{
      valid:true,
      validate: new validation(),
      action: new Actions(),
      loading:false,
      job_id:null,
      register:{
        name: null,
        email: null,
        mobile: null,
        jobrole: [],
        experience: null,
        highest_qualification: null,
        specialisation: null,
        highest_qualification_percentage: null,
        passout_year: null,
        city: null,
        src: null,
        redirect_from: null,
      },
      answers:[],
      domains: [
        "gmail.com",
        "yahoo.com",
        "yahoo.co.in",
        "yahoo.in",
        "rediffmail.com",
        "outlook.com",
        "hotmail.com",
        "ymail.com",
        "live.com",
        "mail.com",
        "rocketmail.com",
        "aol.com",
        "in.com",
        "sify.com"
      ],
      selectedDomains:[],
      showExtensions:false,
      can_register:true,
      upload_data:null,
      experience:"",
      upload_error: "",
      exp: {
        options: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 6, text: "06 Months" },
          { value: 12, text: "1 Year" },
          { value: 24, text: "2 year" },
          { value: 36, text: "3 year" },
          { value: 48, text: "4 year" },
          { value: 60, text: "5 year" },
          { value: 72, text: "6 year" },
          { value: 84, text: "6+ year" },
        ],
      },
      uploading: false,
      moreinfo:false,
      speci:false,
      spec:[],
      job:null,
      application_form:[],
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      upload_progress:0,
      alert_class:null,
      showDismissibleAlert:false,
      success_msg:'',
      cities:[],
      previousUrl:null,
      videoModal:{
        open:'',
      },
      current_key: null,
      validation_error:""
    }
  },
  beforeMount(){
    this.getYears();
    let url = this.$route.path;
    let url_split = url.split("-");
    this.job_id = url_split[url_split.length - 1];
    this.route_src = this.$route.query.src;
    this.register.redirect_from = this.$route.query.redirect_from?this.$route.query.redirect_from:null;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
  },
  mounted(){
    this.action.getFormDetails(this.job_id).then(res=>{
      if(res.status == true){
        this.job = res.job;
        this.question_form = res.questionnaire;
        if(this.question_form.length > 0){
          var self = this;
          var i =0 ;
          this.question_form.filter(function( obj ) {
            let items = [4,15,14,21,18];
            let result = items.every((item)=>{ return obj.qp_id!=item; });
            if(result == true){
              self.answers[i]={answer:null,ans_id:null,qid:obj.qid,deal_breaker:obj.deal_breaker};
              i++;
              if(obj.question_type == 1 || obj.question_type == 2){
                if(obj.qp_id == 6){
                  obj.type = 'date';
                }else if(obj.qp_id == 14|| obj.qp_id == 16|| obj.qp_id == 17|| obj.qp_id == 19 || obj.qp_id == 20 || obj.qp_id == 21 || obj.qp_id == 24){
                  obj.type = 'number';
                }else{
                  obj.type = 'text';
                }
              }else if(obj.question_type == 5){
                if(obj.qp_id == 2){
                  obj.autocomplete = false;
                  obj.multiple = true;
                  obj.values = [];
                  self.jobrole.forEach(role => {
                    obj.values.push({id:role.value,text:role.text});
                  })
                }else if(obj.qp_id == 4 || obj.qp_id == 23){
                  obj.autocomplete = true;
                  obj.values = self.locations;
                }else if(obj.qp_id == 15){
                  obj.autocomplete = true;
                  obj.values = this.courses;
                }else if(obj.qp_id == 7 || obj.qp_id == 8 || obj.qp_id == 22){
                  obj.multiple = true;
                  obj.autocomplete = false;
                  if(obj.qp_id == 7){
                    obj.values=[];
                    self.action.getLanguages().then(data => {
                      if(data.languages.length){
                        data.languages.forEach(language => {
                          obj.values.push({value:language.language,text:language.language});
                        });
                      }
                    });
                  }else if(obj.qp_id == 22){
                    obj.values=[];
                    self.action.getDesignations().then(data => {
                      if(data.designations.length){
                        data.designations.forEach(designation => {
                          obj.values.push({value:designation.designation_id,text:designation.designation_name});
                        });
                      }
                    });
                  }else{
                    obj.values = [];
                    self.action.getSkills().then(data => {
                      if(data.skills.length){
                        data.skills.forEach(skill => {
                          obj.values.push({value:skill.skill,text:skill.skill})
                        });
                      }
                    });
                  }
                }else if(obj.qp_id == 21){
                    obj.autocomplete = true;
                    obj.values = [
                                    { id : 1, text: "0 Month(Fresher)" },
                                    { id : 6, text: "06 Months" },
                                    { id : 12, text: "1 Year" },
                                    { id : 24, text: "2 year" },
                                    { id : 36, text: "3 year" },
                                    { id : 48, text: "4 year" },
                                    { id : 60, text: "5 year" },
                                    { id : 72, text: "6 year" },
                                    { id : 84, text: "6+ year" },
                                  ];
                }else{
                  console.log('here');
                }
              }
              self.application_form.push(obj);
            }
          });
        }
      }
    })
  },
  methods: {
    getVideoPath(value){
      this.answers[this.current_key].answer = value;
    },
    resetModal(){
        this.videoModal.open = '';
    },
    openVideoModal : function(event , type= '', key){
      event.preventDefault();
      console.log('excecutomg......');
        this.videoModal.open = type;
        this.current_key = key;
    },
    resetForm:function(){
        this.register.name = null,
        this.register.email =  null,
        this.register.password= null,
        this.register.mobile= null,
        this.register.jobrole= [],
        this.register.experience= "",
        this.register.highest_qualification= "",
        this.register.specialisation= "",
        this.register.highest_qualification_percentage= null,
        this.register.passout_year= null,
        this.register.city= "",
        this.register.sub_location= null,
        this.register.resume= null,
        this.experience = "",
        this.isSigning = false
        this.upload_error = "";
        this.validation_error = ";"
    },
    getYears(){
      var year = new Date().getFullYear();
      for(var i = year-24; i <year; i++){
        this.years.push({value:i, text:i});
      }
      for(i = year; i<=year+6; i++){
        this.years.push({value:i, text:i});
      }
    },
    
    getExtension(e){
      if(e.key == "@"){
        this.showExtensions = true;
        this.selectedDomains = this.domains
      }else{
        if(this.register.email.includes("@")){
          this.showExtensions = true;
          this.selectedDomains = [];
          var split_email = this.register.email.split("@");
          this.domains.forEach(domain => {
            if(domain.includes(split_email[1])){
              this.selectedDomains.push(domain);
              if(domain == split_email[1]){
                this.showExtensions = false;
              }
            }
          });
        }else{
          this.showExtensions = false
        }
      }
    },
    setExtension(ext){
      var split_email = this.register.email.split("@");
      this.register.email = split_email[0]+"@"+ext;
      this.showExtensions = false;
    },
    hideEmailExtensions(){
      this.showExtensions = false;
      this.selectedDomains = [];
    },
    checkMaximum(){
      if(parseInt(this.register.highest_qualification_percentage) >= 100){
        return this.register.highest_qualification_percentage.substring(0,2);
      }else{
        return this.register.highest_qualification_percentage;
      }
    },
    getSpecification: function () {
      this.spec = [];
      this.register.specialisation = "";
      this.validation = true;
      if (this.register.highest_qualification != null) {
        this.speci = true;
      }
      this.action.getSpec(this.register.highest_qualification).then((data) => {
          data.specifications.forEach((element) => {
            this.spec.push({
              value: element.bid,
              text: element.branch_name,
            });
          });
        });
    },
    async uploadFile(data,key){
      if(data){
        this.upload_error = "";
        this.uploading = true;
        const file = data;
        const awsUploader = new AwsUpload();
        try {
          var extension = data.name.split('.')[data.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);
          var filename = 'resume/'+ current_date+"/"+timestamp + "."+extension;
          const response = await awsUploader.uploadToS3(file, filename);
          this.answers[key].answer = response.url;
          this.uploading = false;
        } catch (error) {
          this.uploading = false;
          this.upload_error = "Failed to upload file to S3:";
          console.error('Failed to upload file to S3:', error);
        }
      }
    },
    submitForm(){
      this.loading = false;
      this.valid = this.$refs.applicationForm.validate();
      let validation_errs = 0;
        this.validation_error = "";
        this.answers.forEach(ans => {
          if(ans.deal_breaker == 1 && !ans.ans_id && !ans.answer) validation_errs ++;
        })
      if(this.valid == true && validation_errs == 0 ){
          this.loading = true;
          this.action.submitPrivateJobForm(this.register,this.answers,this.job_id).then(res=>{
            this.loading = false;
            if(res.message == 'Answer submitted successfully'){
              this.showDismissibleAlert = true;
              this.success_msg = 'Job Application Submitted';
              this.alert_class = 'alert-success';
              this.$router.push({name: "private_job",params: {private_job:this.$route.params.private_job,success:"Your response has been submitted.Thanks!",status:true}});
            } else {
              this.showDismissibleAlert = false;
              this.success_msg = 'Error! Job application not submitted';
              this.alert_class = 'alert-error';
            }
          })
      } else {
        this.loading = false;
        this.validation_error = "Please fill all mandatory feilds"
      }
    }
  }
}
</script>
<style scoped>
.gap{
  padding-top: 0;
  padding-bottom: 0;
}
</style>